<template>
    <div class="careers">
        <layout>
            <template v-slot:header>
                <div class="white">
                    <v-container class="">
                        <breadcrumbs :items="breadcrumbs" :large="true" class="py-7"></breadcrumbs>
                        <h1 class="heading-1 mb-6">Careers</h1>
                    </v-container>
                </div>
            </template>
            <template v-slot:main>
                <v-container class="mb-16 mainpage">
                    <v-row>
                        <v-col cols="12" md="6" v-for="(item, key) in items" :key="key">
                            <career-card
                                :location="item.location"
                                :title="item.title"
                                :date="item.date"
                                :to="item.to"
                                :params="item.params"
                            />
                        </v-col>
                    </v-row>
                </v-container>
                <div class="contact primary py-md-16">
                    <v-container class="my-6">
                        <contact-us />
                    </v-container>
                </div>
            </template>
        </layout>
    </div>
</template>

<script>
import Layout from '@/components/layouts/BlueTopLayout.vue';
import Breadcrumbs from '@/components/base/Breadcrumbs.vue';
import CareerCard from '@/components/base/CareerCard.vue';
import ContactUs from '@/components/pages/ContactUs.vue'


export default {
    components: {
        Layout,
        Breadcrumbs,
        CareerCard,
        ContactUs
    },
    data: () => ({
        breadcrumbs: [
            {
                text: 'Home page',
                to: 'frontend.home',
                disabled: false,
            },
            {
                text: 'Careers',
                disabled: true,
            }
        ],
        items: [
            {
                location: 'Montreal, Canada',
                title: 'Freight Brokers and Independent <br/> Agents Sales Manager for Freight Crew',
                date: '1 August 2020',
                to: 'frontend.career',
                params: {slug: 'sw'}
            },
            {
                location: 'Montreal, Canada',
                title: 'Freight Brokers and Independent <br/> Agents Sales Manager for Freight Crew',
                date: '5 August 2020',
                to: 'frontend.career',
                params: {slug: 'sw'}
            },
            {
                location: 'Los Angeles, USA',
                title: 'Freight Brokers and Independent Agents',
                date: '1 August 2020',
                to: 'frontend.career',
                params: {slug: 'sw'}
            },
            {
                location: 'Montreal, Canada',
                title: 'Sales Manager for Freight Crew',
                date: '1 August 2020',
                to: 'frontend.career',
                params: {slug: 'sw'}
            },
            {
                location: 'Montreal, Canada',
                title: 'Sales Manager for Freight Crew',
                date: '1 August 2020',
                to: 'frontend.career',
                params: {slug: 'sw'}
            },
            {
                location: 'Los Angeles, USA',
                title: 'Freight Brokers and Independent <br/> Agents Sales Manager for Freight Crew',
                date: '1 August 2020',
                to: 'frontend.career',
                params: {slug: 'sw'}
            },
        ]
    })
}
</script>

<style lang="scss">
@media all and (min-width:600px) and (max-width: 1273px){
    .contact.primary{
        height: 610px;
        margin-bottom: 140px;
    }
}
.careers .mainpage{
    background: #fff;
}
</style>
