<template>
    <v-card :color="card_color + ' careercard pa-4 h-100'" @mouseover="mouseOver" @mouseleave="mouseLeave" :to="{name: to, params: params}" flat :ripple="false">
        <v-card-subtitle class="pb-0 pb-md-3">
            <div v-if="location" class="text-16-45">
                <img v-if="!active" src="@/assets/icons/svg/point-orange.svg" class="mr-5" alt="">
                <img v-if="active" src="@/assets/icons/svg/point-white.svg" class="mr-5" alt="">
                {{location}}
            </div>
        </v-card-subtitle>
        <v-card-title class="text-22-60 pt-0" v-html="title"></v-card-title>
        <v-card-actions class="mt-md-5 pt-0 pt-md-3 d-flex justify-space-between">
            <div class="text-14 d-none d-md-flex align-self-center">
                <img v-if="!active" src="@/assets/icons/svg/calendar-silver.svg" class="mr-5" alt="">
                <img v-if="active" src="@/assets/icons/svg/calendar-white.svg" class="mr-5" alt="">
                {{date}}
            </div>
            <v-btn text class="text-transform-none text-16-45" depressed>See details <v-icon small class="ml-5">mdi-chevron-right</v-icon></v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
export default {
    name: 'CareerCard',
    props:['location', 'title', 'date', 'to', 'params'],
    data:() => ({
        active: false,
        card_color: 'silver',
    }),
    methods: {
        mouseOver: function() {
            if(this.$vuetify.breakpoint.mdAndUp){
                this.active = true;
                this.card_color = 'primary';
            }
        },
        mouseLeave: function() {
            this.active = false;
            this.card_color = 'silver';
        }
    }
}

</script>

<style lang="scss">
.careercard:hover, .careercard{
    transition: 0.5s;
}
.careercard.silver{
    .v-card__subtitle div{
        color: #ff842c!important;
    }
    .v-card__actions{
        color: #808080;
    }
}
.careercard.primary{
    .v-card__subtitle div{
        color: #ffffff !important;
    }
    .v-card__title{
        color: #ffffff !important;
    }
    .v-card__actions{
        color: #ffffff !important;
    }
    .v-btn .v-btn__content{
        color: #ffffff !important;
    }
}
@media all and (max-width:960px){
    .text-16-45{
        font-weight: 450;
        font-size: 14px;
        line-height: 24px;
    }
    .text-22-60{
        font-weight: 600;
        font-size: 16px;
        line-height: 26px;
    }
}
</style>
